import Vue from 'vue'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css';
import axios from '../node_modules/axios'
import qs from 'qs'
import resultCode from '@/utils/code'
import VueCookies from 'vue-cookies'



//引用富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
//引入富文本css
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import App from './App.vue'
import router from './router'
import {
	asyncRoutes
} from './router/routers'

import urA_http from '@/utils/request'
// excel
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
//富文本编辑器添加实例
Vue.use(VueQuillEditor)
Vue.use(ElementUI,{ size: 'small'});
Vue.use(VueCookies)
Vue.prototype.resultCode = resultCode
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
Vue.prototype.$qs = qs;




axios.defaults.withCredentials = false
const whiteList = ['/login']

// 校验是否登陆  只要路由跳转，刷新   进行加载

router.beforeEach((to, from, next) => {
	// console.log(to);
	//console.log(from);
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (VueCookies.get("token")) { //判断本地是否存在access_token
			next();
		} else {
			next({
				path: 'login',
				query: {

					redirect: to.fullPath

				}
			})

		}
	} else {
		next();
	}


	/*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
	if (to.fullPath == "/login") {
		if (VueCookies.get("token")) {
			next({
				path: from.fullPath
			});
		} else {
			next();
		}
	}
});

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
// .$mount('#app')
